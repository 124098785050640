import React, { useEffect, useRef, useState } from "react"
import { useWindowSize } from "../hooks/windowsize"
import Styles from "../styles/modules/carousel.module.scss"
import Img from "gatsby-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger)
}

export default props => {
  const { images } = props
  const breakpoint = 992
  const size = useWindowSize()
  const containerRef = useRef(null)
  const stickyRef = useRef(null)
  const swiper = useRef(null)

  const [swiperUpdated, setSwiperUpdated] = useState(false)

  const tl = useRef(gsap.timeline({ paused: true, ease: "none" }))

  useEffect(() => {
    if (size.width > breakpoint) {
      const carousel = swiper.current.swiper
      carousel.update()
      const scrollWidth = carousel.virtualSize

      tl.current.to(
        {},
        {
          scrollTrigger: {
            id: "carousel",
            trigger: containerRef.current,
            start: "top 100px",
            end: containerRef.current.offsetTop + scrollWidth - size.width,
            pin: stickyRef.current,
            anticipatePin: 1,
          },
        }
      )

      tl.current.to(
        {},
        {
          scrollTrigger: {
            trigger: containerRef.current,
            start: "top 20%",
            end: containerRef.current.offsetTop + scrollWidth - size.width,
            anticipatePin: 1,
            onUpdate: ({ progress }) => {
              const trans = -1 * progress * (scrollWidth - size.width)
              gsap.to(carousel.wrapperEl, {
                x: trans + "px",
              })
            },
          },
        }
      )
    }

    const timeline = tl.current
    return () => {
      timeline.kill()
      timeline.getChildren().map(v => {
        return v.scrollTrigger.kill()
      })
    }
  }, [size.width, swiperUpdated])

  return (
    <div className={Styles.container} ref={containerRef}>
      <div className={`${Styles.sticky} ${props.className}`} ref={stickyRef}>
        <Swiper
          className={Styles.translateContainer}
          ref={swiper}
          noSwiping={size.width > breakpoint}
          onBreakpoint={() => {
            setSwiperUpdated(swiperUpdated => !swiperUpdated)
          }}
          slidesPerView={props.slidesPerView}
          spaceBetween={props.spaceBetween}
          breakpoints={props.breakpoints}
        >
          {images.map((o, i) => (
            <SwiperSlide className="swiper-no-swiping" key={i}>
              <Img
                className="carousel-gatsby-image-wrapper"
                fluid={o.childImageSharp.fluid}
                draggable={false}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
